<template>
  <div>
    <v-card>
      <v-list style="margin: 10px">
        <h4 class="panelTitle">باشگاه مشتریان</h4>
        <hr style="margin-top: 1rem;margin-bottom: 1rem">
        <v-row>
          <v-col style="margin-top:10px;">لطفا کد کمپین را وارد کنید</v-col>
          <v-col>
            <input v-model="campaignCode" class="Input">
          </v-col>
          <v-col>
            <v-btn @click="GetEligibleUsersForCampaignGifts()" color="success">جست و جو</v-btn>
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-list>
    </v-card>
    <v-card v-if="tableShow">
      <v-card-title>
        کاربران
        <v-spacer></v-spacer>
        <v-text-field class="searchStyle" v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details></v-text-field>
      </v-card-title>
      <v-data-table :headers="header" :items="eligibleUsersForCampaignGift" :search="search">
        <template v-slot:item.operations="{ item }">
          <v-btn v-if="item.totalSubsetUsers !== 0" color="success" @click="showDetail(item.subsetUsers , item.username)"> جزییات</v-btn>
        </template>
      </v-data-table>
    </v-card>
    <Popup width="900px" :show.sync="CustomerPopup">
      <template slot="body">
        <v-toolbar color="primary" dark class="rtl">
          لیست کاربران واجد شرایط
        </v-toolbar>
        <v-card-text class="rtl">
          <v-data-table :headers="headerDetail" :items="detailItem" :search="searchDetail">
            <template v-slot:item.operations="{ item }">
              <v-btn color="success" @click="SendCampaignBonus(item.username)"> پرداخت</v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </template>
    </Popup>
  </div>
</template>

<script>
import axiosApi from "@/axios";
import Vue from "vue";
import Popup from "@/components/Popup";

export default {
  name: "index",
  components: {
    Popup
  },
  data() {
    return {
      campaignCode: '',
      tableShow: false,
      eligibleUsersForCampaignGift: [],
      search: '',
      searchDetail: '',
      header: [
        { text: 'شماره تلفن', value: 'username' },
        { text: 'تعداد کاربران واجد شرایط', value: 'totalSubsetUsers' },
        { text: 'تعداد پرداخت نشده', value: 'unpaidSubsetUsers' },
        { text: 'تعداد پرداخت شده', value: 'paidSubsetUsers' },
        { text: 'عملیات', value: 'operations' },
      ],
      headerDetail: [
        { text: 'شماره تلفن', value: 'username' },
        { text: 'مبلغ کمپین', value: 'totalCompletedOrdersVolumeInPrice' },
        { text: 'عملیات', value: 'operations' }
      ],
      CustomerPopup: false,
      detailItem: [],
      username: '',

    };
  },
  methods: {
    GetEligibleUsersForCampaignGifts() {
      axiosApi().post('api/Manage/v1/CustomerClub/GetEligibleUsersForCampaignGift', {
        campaignCode: this.campaignCode
      })
        .then(({ data }) => {
          if (data['isSuccess']) {
            this.eligibleUsersForCampaignGift = data.data.eligibleUsersForCampaignGift;
            this.tableShow = true;
          } else {
            Vue.$toast.open({
              message: 'کد نا معتبر است',
              type: 'error',
              position: 'top-right'
            });
          }
        });
    },
    showDetail(items, username) {
      this.CustomerPopup = true;
      this.username = username;
      this.detailItem = items;
    },
    SendCampaignBonus(username) {
      axiosApi().post('api/Manage/v1/CustomerClub/SendCampaignBonus', {
        "username": this.username,
        "subsetUserUsername": username,
        "campaignCode": this.campaignCode
      }).then(({ data }) => {
        if (data['isSuccess']) {
          Vue.$toast.open({
            message: 'عملیات با موفقیت انجام شد',
            type: 'success',
            position: 'top-right'
          });
          this.CustomerPopup = false;
          this.GetEligibleUsersForCampaignGifts(this.campaignCode);
        }
      }).catch(() => {
        Vue.$toast.open({
          message: 'مشکلی به وجود امده است لطفا مجدد تلاش کنید',
          type: 'error',
          position: 'top-right'
        });
      });
    }
  }
};
</script>

<style scoped>

</style>